import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { useStoryblok } from '@/storyblok/hooks/useStoryblok'
import Error from '@/templates/Error'
import PageBlueprint from '@/storyblok/blueprints/Page.blueprint.jsx'

const NotFoundPage = ({ location }) => {
  const story = useStoryblok(null, location)

  // render unpublished content
  if (story && story.content) {
    return <PageBlueprint data={null} location={location} />
  }

  return (
    <Error title="Page Not found">
      <p>
        If you entered a web address please check it was correct, or{' '}
        <Link to="/">click here</Link> to go to the homepage.
      </p>
    </Error>
  )
}

NotFoundPage.propTypes = {
  location: PropTypes.object,
}

export default NotFoundPage
